import React, { FC, useState } from 'react'
import { FaInstagram } from 'react-icons/fa'
import HamburgerMenu from 'react-hamburger-menu'
import ScrollLock from 'react-scrolllock'
import { Container } from '../container'
import { Link } from '../link'
import { HeaderContainer, HeaderHamburgerContainer, HeaderNav, HeaderNavContainer, HeaderNavList, HeaderNavListItem, HeaderSocial, HeaderSocialItem } from './styles'
import { CompactLogo, Logo } from '../logo'
import { DesktopOnly, MobileOnly } from '../util'

interface HeaderProps { }

export const Header: FC<HeaderProps> = () => {
  const [state, setState] = useState(false)

  const handleClick = () => {
    setState((prevState) => (!prevState))
  }

  return (
    <Container padding-top-none>
      <HeaderContainer>
        <Link hideUnderline={true} to='/'>
          <MobileOnly>
            <CompactLogo />
          </MobileOnly>
          <DesktopOnly>
            <Logo />
          </DesktopOnly>
        </Link>
        <HeaderNav>
          <HeaderHamburgerContainer>
            <HamburgerMenu
              isOpen={state}
              menuClicked={handleClick}
              width={32}
              height={24}
            />
          </HeaderHamburgerContainer>
          <ScrollLock isActive={state} />
          <HeaderNavContainer isOpen={state}>
            <HeaderNavList>
              <HeaderNavListItem>
                <Link to='/work'>
                  Work
                </Link>
              </HeaderNavListItem>
              <HeaderNavListItem>
                <Link to='/about'>
                  About
                </Link>
              </HeaderNavListItem>
              <HeaderNavListItem>
                <Link to='/contact'>
                  Contact
                </Link>
              </HeaderNavListItem>
            </HeaderNavList>
            <HeaderSocial>
              <HeaderSocialItem>
                <Link hideUnderline={true} to='https://instagram.com/mandhstyling'>
                  <FaInstagram size='1.5em' />
                </Link>
              </HeaderSocialItem>
            </HeaderSocial>
          </HeaderNavContainer>
        </HeaderNav>
      </HeaderContainer>
    </Container>
  )
}
