import { createGlobalStyle } from 'styled-components'
import { above } from './mixins'

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    font-family: Raleway, sans-serif;
    line-height: 1.65;
  }

  html {
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    font-size: 16px;

    ${above.md`
      font-size: 18px;
    `}
  }

  p {
    margin-bottom: 1.15rem;
    font-size: 1.2em;

    ${above.md`
      font-size: 1.25rem;
    `}
  }

  p a {
    color: ${(props) => props.theme.colors.link.text};
    padding-bottom: ${(props) => props.theme.spacing.sm};
    text-decoration: none;
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, currentColor 100%, currentColor 0);
    background-size: 1px 1px;
    background-position: 0 calc(100% - .1em);
    transition: background-position .15s ease;
    text-underline: none;

    :hover {
      background-position: 0 100%;
    }
  }

  h1, h2, h3, h4, h5 {
    margin: 0 0 .25em;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    line-height: 1.025;
  }

  h1 {
    margin-top: 0;
    font-size: 2.488em;

    ${above.md`
      font-size: 3.052rem;
    `}
  }

  h2 {
    font-size: 2.074em;

    ${above.md`
      font-size: 2.441rem;
    `}
  }

  h3 {
    font-size: 1.728em;

    ${above.md`
      font-size: 1.953rem;
    `}
  }

  h4 {
    font-size: 1.44em;

    ${above.md`
      font-size: 1.563rem;
    `}
  }

  h5 {
    font-size: 1.2em;

    ${above.md`
      font-size: 1.25rem;
    `}
  }
`
