export default {
  breakpoints: {
    // max-widths in pixels
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1440,
  },
  colors: {
    link: {
      text: '#000',
    },
    button: {
      background: '#000',
      text: '#FFF',
    },
    input: {
      border: '#000',
    },
    background: '#FFF',
    text: '#000',
  },
  opacity: {
    0: 0,
    25: 0.25,
    50: 0.5,
    75: 0.75,
    100: 1,
  },
  animation: {
    slow: '.5s',
    medium: '.25s',
    fast: '.1s',
  },
  spacing: {
    none: 0,
    xxs: '2px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '32px',
    xl: '64px',
    xxl: '128px',
  },
  typography: {
    sizes: {
      sm: '1rem',
      md: '1.25rem',
      lg: '1.563rem',
      xl: '1.953rem',
      xxl: '2.441rem',
      xxxl: '3.052rem',
    },
  },
  py: (value: number | string) =>
    `padding-top: ${value}; padding-bottom: ${value};`,
  // Add anything else you'd like.
}
