import loadable from '@loadable/component'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { theme, GlobalStyles } from '../../styles'

const StoryProvider = loadable(() => import(/* webpackChunkName: "storyblok-toolkit" */'@storyofams/storyblok-toolkit'), {
  resolveComponent: ({ StoryProvider }) => StoryProvider,
})

// Components
import { Footer } from '../footer'
import { Header } from '../header'

const Container = styled.div`
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing.lg};
`;

const Main = styled.main``

export const Layout: React.FC = ({ children }) => {
  return (
    <StoryProvider>
      <ThemeProvider theme={theme()}>
        <Container>
          <GlobalStyles />
          <Header />
          <Main>{children}</Main>
          <Footer />
        </Container>
      </ThemeProvider>
    </StoryProvider>
  )
}
