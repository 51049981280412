import styled from 'styled-components'
import { ContainerProps } from '.'
import { marginsPadding } from '../../styles/mixins'
import { MaxWidth } from '../../utils'

export const ContainerElement = styled.section<ContainerProps>`
  padding: ${(props) => props.theme.spacing.lg} 0;
  max-width: ${(props) => props.fixed ? MaxWidth : 'initial'};
  margin: ${(props) => props.fixed ? '0 auto' : 'initial'};
  text-align: ${(props) => props.align ? props.align : 'left'};
  ${marginsPadding}
`
