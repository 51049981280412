import { useContext } from 'react'
import { ThemeContext, DefaultTheme } from 'styled-components'
import media from 'use-media'
import themeObject from './theme'
export { GlobalStyles } from './global-styles'

type DefaultThemeObject = Omit<typeof themeObject, 'screens'> & {
  breakpoints: {
    [key: string]: number
  }
}

const theme = (): DefaultTheme => {
  const { breakpoints, ...themeValues }: DefaultThemeObject = themeObject
  const breakpointSizes = Object.keys(breakpoints).reduce(
    (accum, key: string) => {
      const value = media({ maxWidth: breakpoints[key] })
      return {
        ...accum,
        [key]: value,
      }
    },
    {}
  )
  return {
    ...themeValues,
    breakpoints: breakpointSizes,
  }
}

const useTheme = () => useContext(ThemeContext)

export { theme, useTheme }
