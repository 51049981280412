import styled from 'styled-components'
import { above, below } from '../../styles/mixins'

export const MobileOnly = styled.div`
  ${above.md`
    display: none;
  `}
`

export const DesktopOnly = styled.div`
  ${below.md`
    display: none;
  `}
`
