import styled, { ThemeProps } from 'styled-components'
import { CustomThemeProps } from '..'
import { below, above } from '../../styles/mixins'

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const HeaderNav = styled.nav`
  display: flex;
  flex-direction: row;
`

export const HeaderHamburgerContainer = styled.div`
  margin-top: 2px;

  ${above.md`
    display: none;
  `}
`

interface HeaderNavContainerProps extends ThemeProps<CustomThemeProps> {
  isOpen: boolean
}

export const HeaderNavContainer = styled.div<HeaderNavContainerProps>`
  display: flex;
  flex-direction: column;

  ${below.md`
    position: fixed;
    width: 100vw;
    height: calc(100vh - 150px);
    background: ${(props) => props.theme.colors.background};
    transition: all ${(props) => props.theme.animation.medium} ease;
    opacity: ${(props) => props.isOpen ? '1' : '0'};
    top: 0;
    left: 0;
    transform: translate(0, ${(props) => props.isOpen ? '150px' : '100vh'});
    z-index: 10000;
  `}

  ${above.md`
    flex-direction: row;
    margin-right: ${(props) => props.theme.spacing.md};
  `}
`

export const HeaderNavList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  ${below.md`
    flex: .85;
    justify-content: center;
    align-items: center;
  `}

  ${above.md`
    flex-direction: row;
    margin-right: ${(props) => props.theme.spacing.md};
  `}
`

export const HeaderNavListItem = styled.li`
  list-style: none;
  padding: 0 ${(props) => props.theme.spacing.sm};
  font-size: ${(props) => props.theme.typography.sizes.xl};

  ${above.md`
    margin: ${(props) => props.theme.spacing.sm};
    font-size: ${(props) => props.theme.typography.sizes.md};
  `}
`

export const HeaderSocial = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  justify-content: center;

  ${below.md`
    flex: .15;
  `}
`

export const HeaderSocialItem = styled.li`
  display: flex;

  ${above.md`
    padding-left: ${(props) => props.theme.spacing.lg};
  `}

  a {
    display: flex;
    padding-bottom: 0;
  }
`
