import styled from "styled-components";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";

interface LinkProps extends GatsbyLinkProps<any> {
  hideUnderline?: boolean
}

export const Link = styled(GatsbyLink)<LinkProps>`
  color: ${(props) => props.theme.colors.link.text};
  padding-bottom: ${(props) => props.theme.spacing.sm};
  text-decoration: none;
  line-height: 2;

  ${(props) => !props.hideUnderline && `
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, currentColor 100%, currentColor 0);
    background-size: 1px 1px;
    background-position: 0 calc(100% - .1em);
    transition: background-position .15s ease;`
  }
  :hover {
    background-position: 0 100%;
  }
`
