import { css } from 'styled-components'
import theme from './theme'

export const above = Object.keys(theme.breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (min-width: ${theme.breakpoints[label]}px) {
        ${css(...args)};
      }
    `
    return accumulator
  },
  {}
)

export const below = Object.keys(theme.breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (max-width: ${theme.breakpoints[label]}px) {
        ${css(...args)};
      }
    `
    return accumulator
  },
  {}
)

export const marginsPadding = (props: any) => {
  let classes = ``
  const properties = ['padding', 'margin']
  const spacing = props.theme.spacing

  Object.keys(props).forEach((prop) => {
    properties.forEach((property) => {
      if (prop.startsWith(property)) {
        const [, axis, key] = prop.split('-')
        classes += `${property}-${axis}: ${spacing[key]};`
      }
    })
  })

  return css`
    ${classes}
  `
}
