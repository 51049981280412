import React, { FC } from 'react'
import { ContainerElement } from './styles'

export interface ContainerBlockProps {
  fixed_width?: boolean
  padding_top?: string
  padding_left?: string
  padding_bottom?: string
  padding_right?: string
  children: any
  align?: string
}

export interface ContainerProps {
  blok?: ContainerBlockProps
  fixed?: boolean
  align?: string
  'padding-top-none'?: boolean
  'padding-top-xxs'?: boolean
  'padding-top-xs'?: boolean
  'padding-top-sm'?: boolean
  'padding-top-md'?: boolean
  'padding-top-lg'?: boolean
  'padding-top-xl'?: boolean
  'padding-top-xxl'?: boolean
  'padding-top-xxxl'?: boolean
  'padding-top-xxxxl'?: boolean
  'padding-bottom-none'?: boolean
  'padding-bottom-xxs'?: boolean
  'padding-bottom-xs'?: boolean
  'padding-bottom-sm'?: boolean
  'padding-bottom-md'?: boolean
  'padding-bottom-lg'?: boolean
  'padding-bottom-xl'?: boolean
  'padding-bottom-xxl'?: boolean
  'padding-bottom-xxxl'?: boolean
  'padding-bottom-xxxxl'?: boolean
  'padding-left-none'?: boolean
  'padding-left-xxs'?: boolean
  'padding-left-xs'?: boolean
  'padding-left-sm'?: boolean
  'padding-left-md'?: boolean
  'padding-left-lg'?: boolean
  'padding-left-xl'?: boolean
  'padding-left-xxl'?: boolean
  'padding-left-xxxl'?: boolean
  'padding-left-xxxxl'?: boolean
  'padding-right-none'?: boolean
  'padding-right-xxs'?: boolean
  'padding-right-xs'?: boolean
  'padding-right-sm'?: boolean
  'padding-right-md'?: boolean
  'padding-right-lg'?: boolean
  'padding-right-xl'?: boolean
  'padding-right-xxl'?: boolean
  'padding-right-xxxl'?: boolean
  'padding-right-xxxxl'?: boolean
  'margin-top-none'?: boolean
  'margin-top-xxs'?: boolean
  'margin-top-xs'?: boolean
  'margin-top-sm'?: boolean
  'margin-top-md'?: boolean
  'margin-top-lg'?: boolean
  'margin-top-xl'?: boolean
  'margin-top-xxl'?: boolean
  'margin-top-xxxl'?: boolean
  'margin-top-xxxxl'?: boolean
  'margin-bottom-none'?: boolean
  'margin-bottom-xxs'?: boolean
  'margin-bottom-xs'?: boolean
  'margin-bottom-sm'?: boolean
  'margin-bottom-md'?: boolean
  'margin-bottom-lg'?: boolean
  'margin-bottom-xl'?: boolean
  'margin-bottom-xxl'?: boolean
  'margin-bottom-xxxl'?: boolean
  'margin-bottom-xxxxl'?: boolean
  'margin-left-none'?: boolean
  'margin-left-xxs'?: boolean
  'margin-left-xs'?: boolean
  'margin-left-sm'?: boolean
  'margin-left-md'?: boolean
  'margin-left-lg'?: boolean
  'margin-left-xl'?: boolean
  'margin-left-xxl'?: boolean
  'margin-left-xxxl'?: boolean
  'margin-left-xxxxl'?: boolean
  'margin-right-none'?: boolean
  'margin-right-xxs'?: boolean
  'margin-right-xs'?: boolean
  'margin-right-sm'?: boolean
  'margin-right-md'?: boolean
  'margin-right-lg'?: boolean
  'margin-right-xl'?: boolean
  'margin-right-xxl'?: boolean
  'margin-right-xxxl'?: boolean
  'margin-right-xxxxl'?: boolean
}

export const Container: FC<ContainerProps> = ({ children, ...rest }) => {
  return (
    <ContainerElement {...rest}>{children}</ContainerElement>
  )
}
